<template>
  <div
    class="container eleListExpiringDate"
    v-title
    :data-title="$t('i18n.eleListExpiringDate')"
  >
    <div id="outer-title">{{ $t("i18n.eleListExpiringDate") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
// import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "RemindyearCheck",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    // const { t } = useI18n();
    const state = reactive({
      formInline: {
        page: 1,
        pageSize: 10,
      },
      dateRange: "",
      dialogUpload: false,
      dialogDownload: false,
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          type: "input",
          rows: false,
          search: true,
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          search: true,
          type: "input",
          align: "left",
        },
        {
          prop: "annualValidity",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "nextAnnualDate",
          label: "nextAnnualInspectionDate",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 170,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.getRemindersData(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss">
.eleListExpiringDate {
  .el-form-inline {
    .el-input {
      line-height: unset;
    }
  }
}
</style>
